import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Styled from './modalTemplateStyes';
// import EBillingPopUp from '../../eBillingPopUp/';

const ModalTemplate = (props) => {
  const [modalShowEBilling, setModalShowEBilling] = React.useState(false);
  const handleModalShowEBilling = (isShow) => {
    isShow
      ? setModalShowEBilling(isShow)
      : setModalShowEBilling(!modalShowEBilling);
  };
  return props.imageSource ? (
    // <Styled.AnchorStyles
    //   href={
    //     props?.anchorLink === '/modal/ebilling/' ? undefined : props?.anchorLink
    //   }
    //   onClick={() => {
    //     props?.anchorLink === '/modal/ebilling/'
    //       ? handleModalShowEBilling()
    //       : {};
    //   }}
    // >
    <>
      <Col xl={12}>
        <Styled.ModalImage
          src={props?.imageSource}
          alt={props?.altText}
          $width={props?.anchorLink === '/modal/ebilling/'}
        ></Styled.ModalImage>
        <Styled.HeaderText>{props?.headerText}</Styled.HeaderText>
        <Styled.ParaText>{props?.paraText}</Styled.ParaText>
      </Col>
      {/* <EBillingPopUp
        modalShow={modalShowEBilling}
        handleModalShow={handleModalShowEBilling}
      /> */}
    </>
  ) : (
    <></>
  );
};

export default ModalTemplate;
